import React from "react";
import { Link } from "react-router-dom";

export const CustomBtn = ({ to, title }) => {
  return (
    <Link className="secondary-btn" to={to}>
      {title}
    </Link>
  );
};
