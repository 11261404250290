import React from "react";
import {
  deploymentIcon,
  devIcon,
  marketStudIcon,
  planningIcon,
  qaIcon,
  UXUIIcon,
  process1,
  process2,
  process3,
} from "../../components/UI/Library";
import { HeaderTitle } from "../../components/UI/rows/HeaderTitle";
import { OppWidgetContainer } from "../../components/UI/rows/OppWidgetContainer";
import { WidgetContainer } from "../../components/UI/rows/WidgetContainer";
import { MobileProcess } from "./MobileProcess";
import { WideScreenProcess } from "./WideScreenProcess";
import { useWindowWidthAndHeight } from "../../components/hooks/CustomHooks";
import Fade from 'react-reveal/Fade';

export const OurProcess = () => {
  const [width, height] = useWindowWidthAndHeight();
  // console.log("window ", window, window.scrollY);
  return (
    <div className="ourProcess">
      <div className="ourProcess__intro">
        <div className="ourProcess__intro-row">
          <HeaderTitle
            preTitle="WEBSITE BY MOOBITEK"
            title="Our clients’ experience with us leads to digital products, perfectly engineered to match business goals with user needs."
            postTtile="  Only when our clients declare success, we celebrate our success."
          />
        </div>
      </div>
      <div className="ourProcess__process">
        {width < 1000 ? <MobileProcess /> : <WideScreenProcess />}
      </div>
      <div className="ourProcess__devProcess">
        <Fade bottom>
          <div className="ourProcess__devProcess-row">
            <h2>End-to-end software development process</h2>
            <p className="paragraph">
              First, your ideas turn into a prototype. The UX/UI design provides
              then a mock-up product to test, gather feedback and validate the
              concept which helps you to detect any open questions or mistakes
              early within the process which will pay off later down the road.
              For instance, reconfiguring a feature of the prototype may cost
              you two hours, while making the same changes in the development
              phase may cost you one full week.
            </p>
            <p className="paragraph">
              For the entire lifecycle of the product, the concept of “Agile
              Methodology” is used and serves as the base for all of our
              development framework. You will interact with the team for
              biweekly planning sessions, daily progress check-ups, and monthly
              meetings for roadmap updates. Here, we use the concept of “Scrum”
              offering great visibility on the progress and transparency.
            </p>

          </div>
        </Fade>
      </div>
      <WidgetContainer
        title="Product discovery"
        spacedBody={
          <>
            <p className="paragraph">
              You are in the steering seat -- you tell us what your vision is,
              explain your business goals, and every component of importance for
              the project. Our task is to listen. If you have a precise idea and
              vision, our responsibility is to ask challenging questions, to
              push for details and unplanned scenarios. If your idea still needs
              clarity and details, we ask expert questions, offer knowledgeable
              suggestions until you picture a clearly defined concept with
              specific details. We want to be part of your team!
            </p>
            <p className="paragraph">
              Once we have a clear joint vision, we start working on the
              estimates based on our agreed outline. We do in-depth capacity and
              resource planning and will be able to give you clear estimates: we
              offer visibility and predictability of costs and timelines. You
              will be able to see our commitment to your success from the very
              beginning.
            </p>
            <p className="paragraph">
              Once the project is kick-off off, we assemble a cross-functional
              team that will work side by side through the entire process.
            </p>
          </>
        }
        img1={process1}
      />
      <OppWidgetContainer
        imgClass="representation"
        img1={process2}
        title="UX/UI Design"
        spacedBody={
          <>
            <p className="paragraph">
              The UX/UI Design Phase should be the mandatory first step in the
              process of building new products. This approach saves you money.
              For more than 10 years, we have worked with numerous clients and
              developed various products. Our experience has proven time and
              again that this approach can have a major impact on the overall
              financial investment of our clients.
            </p>
            <p className="paragraph">
              With UX, you gain clarity and a deeper understanding of your users
              and their specific needs. Your concept is fine-tuned to answer
              market needs.
            </p>
            <p className="paragraph">
              Your refined concept develops into a prototype. This is decisive
              when we get into the MVP phase. With a prototype validated by
              end-users, development costs are much reduced.
            </p>
          </>
        }
      />
      <WidgetContainer
        title="Release, Maintenance and Support"
        spacedBody={
          <>
            <p className="paragraph">
              You receive a live demo of the component we work on, every two
              weeks. You gain complete visibility into the progress that leads
              to the final product. You benefit from a frequent, gradual release
              of segments of your product, at the end of each development
              iteration.
            </p>
            <p className="paragraph">
              When your product is ready for release, we are there to make it
              happen.
            </p>
            <p className="paragraph">
              You will see how your product begins working for you. And we are
              still your team. We accompany you along the way by offering
              maintenance and support services. We monitor the application and
              its environment; back up data periodically, update software and
              hardware to ensure the product works properly after the product is
              in use.
            </p>
          </>
        }
        img1={process3}
      />
    </div>
  );
};
