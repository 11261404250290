import React from "react";
import { FilledBlock } from "./FilledBlock";
import {
  deploymentIcon,
  devIcon,
  marketStudIcon,
  planningIcon,
  qaIcon,
  UXUIIcon,
  process1,
  process2,
  process3,
} from "../../components/UI/Library";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";

export const WideScreenProcess = () => {
  return (
    <>
     <Fade>
      <div className="text1">
        <div className="Empty" />
        <Fade top delay={100}>
          <FilledBlock
            number="02"
            title="Planning"
            body="We set our precise work process and how it will be executed. All steps needed will be outlined as part of a full project management plan."
          />
        </Fade>
        <div className="Empty" />
        <Fade top delay={250}>
          <FilledBlock
            number="04"
            title="Development"
            body="Our team start development with a test area for all parties to have full transparency at all times. The development consists of both the front and back end"
          />
        </Fade>
        <div className="Empty" />
        <Fade top delay={300}>
          <FilledBlock
            number="06"
            title="Deployment"
            body="The project is switched online and live"
          />
        </Fade>
      </div>
      <div className="ourProcess__process-row">
        <ul>
          <li className="impair">
            <Zoom delay={100}>
              <div>
                <img src={marketStudIcon} />
              </div>
            </Zoom>
            <div className="transparent-circle"></div>
          </li>
          <li className="pair">
            <Zoom delay={200}>
              <div>
                <img src={planningIcon} />
              </div>
            </Zoom>
            <div className="transparent-circle"></div>
          </li>
          <li className="impair">
            <Zoom delay={300}>
              <div>
                <img src={UXUIIcon} />
              </div>
            </Zoom>
            <div className="transparent-circle"></div>
          </li>
          <li className="pair">
            <Zoom delay={400}>
              <div>
                <img src={devIcon} />
              </div>
            </Zoom>
            <div className="transparent-circle"></div>
          </li>
          <li className="impair">
            <Zoom delay={500}>
              <div>
                <img src={qaIcon} />
              </div>
            </Zoom>
            <div className="transparent-circle"></div>
          </li>
          <li className="pair">
            <Zoom delay={600}>
              <div>
                <img src={deploymentIcon} />
              </div>
            </Zoom>
            <div className="transparent-circle"></div>
          </li>
        </ul>
      </div>
      <div className="text1" style={{ top: "-105px" }}>
        <Fade bottom delay={100}>
          <FilledBlock
            top={true}
            number="01"
            title="Market Study"
            body="After the client's request, it is a must to conduct extensive research with an in-depth market study."
          />
        </Fade>
        <div className="Empty" />
        <Fade bottom delay={250}>
          <FilledBlock
            top={true}
            number="03"
            title="UX / UI"
            body="Our designers wireframe and build test spaces as first mock-ups, which then triggers the UX / UI design development."
          />
        </Fade>
        <div className="Empty" />
        <Fade bottom delay={300}>
          <FilledBlock
            top={true}
            number="05"
            title="QA / Testing"
            body="The final part before the project goes live, where our Q&A team will be testing the whole environment for any bugs and issues they may encounter. This leads to a 100% refined and clean new web or app."
          />
        </Fade>
        <div className="Empty" />
      </div>
      </Fade>
    </>
  );
};
