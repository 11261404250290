import { useEffect, useState, useRef } from "react";
import { useWindowWidthAndHeight } from "./CustomHooks";

export const OnScrollY = () => {
  let prevScrollY = useRef(0);
  const [width, height] = useWindowWidthAndHeight();

  let [goingUp, setGoingUp] = useState(false);
  let [currentScrollY, setcurrentScrollY] = useState({
    currentScrollY: 0,
    page: 0,
  });
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (prevScrollY.current < currentScrollY && goingUp) {
        setGoingUp(false);
      }
      if (prevScrollY.current > currentScrollY && !goingUp) {
        setGoingUp(true);
      }

      prevScrollY.current = currentScrollY;
      // console.log(
      //   goingUp,
      //   currentScrollY,
      //   height,
        
      // );
      setcurrentScrollY({
        currentScrollY,
        page: (currentScrollY / height).toFixed(2),
      });
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [goingUp]);

  return currentScrollY;
};
