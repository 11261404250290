import React, { useState, useEffect } from "react";
import { homeValue1, homeValue2, homeValue3 } from "../Library";

export const Carousel = ({ imgs, team, mobile }) => {
  const [load, setLoad] = useState(false);
  let slideIndex = 1;
  let timer;

  const starttimer = () => {
    clearInterval(timer);

    timer = setInterval(() => {
      plusSlides(+1);
    }, 3000);
  };

  useEffect(() => {
    starttimer();

    return () => clearInterval(timer);
  });

  const plusSlides = (n) => {
    if (n == -1 && slideIndex == 1) slideIndex = imgs.length;
    else slideIndex += n;
    showSlides(slideIndex);
  };

  const currentSlide = (n) => {
    showSlides((slideIndex = n));
  };

  const showSlides = (n) => {
    var i;
    var slides = document.getElementsByClassName("mySlides");
    // var dots = document.getElementsByClassName("dot");
    if (n > slides.length) {
      slideIndex = 1;
    }

    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    // for (i = 0; i < dots.length; i++) {
    //   dots[i].className = dots[i].className.replace(" active", "");
    // }

    // if (slides.length>0) {
    slides[slideIndex - 1].style.display = "flex";
    // dots[slideIndex - 1].className += " active";
    // }
  };
  //
  return (
    <div className="carousel" style={{ display: team ? "block" : "" }}>
      <div
        className="carousel__slideshow-container"
        // style={{ height: "50vh" }}
      >
        {imgs.map((e, i) => (
          <div
            key={i}
            className="mySlides fade"
            style={{ height: team ? "" : "100px" }}
          >
            <img src={e.logo} style={{ width: e.width }} />
          </div>
        ))}

        <a
          className="prev"
          onClick={() => {
            plusSlides(-1);
            starttimer();
          }}
          style={{ left: team && !mobile ? "-10%" : 0 }}
        >
          &#10094;
        </a>
        <a
          className="next"
          onClick={() => {
            plusSlides(1);
            starttimer();
          }}
          style={{ right: team && !mobile ? "-10%" : 0 }}
        >
          &#10095;
        </a>
      </div>
      <br />

      {/* <div style={{ textAlign: "center" }}>
        {imgs.map((e, i) => (
          <span className="dot" onClick={() => currentSlide(i + 1)}></span>
        ))}
      </div> */}
    </div>
  );
};
