import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DefaultBtn } from "../UI/buttons/DefaultBtn";
import {
  map,
  logo,
  fbIcon,
  instaIcon,
  linkedinIcon,
  twitterIcon,
} from "../UI/Library";
import Canvas from "./Canvas";
import { Popup } from "../../components/UI/Popup";
import Fade from "react-reveal/Fade";
import Flip from 'react-reveal/Flip';
export const Footer = () => {
  const [modal, setModal] = useState(false);
  const [country, setcountry] = useState('')
  // const canvas = this.canvas;
  useEffect(() => {
    return () => {};
  }, []);

  const nav = [
    { title: "OUR WORK", link: "our-work" },
    { title: "SOLUTIONS", link: "solutions" },
    { title: "ABOUT", link: "about" },
    { title: "CONTACT", link: "contact" },
  ];

  return (
    <div className="footer">
      <Popup display={modal} setModal={setModal} type={"Quotation"} />
      <div className="footer__head">
        <Fade bottom cascade>
          <div className="footer__head-first">
            <h2>Achieve the extraordinary</h2>
            <span onClick={() => setModal(true)}>
              <DefaultBtn content="REQUEST A QUOTE" />
            </span>
          </div>
        </Fade>

        {/* <canvas
          id="canvas"
          style={{
            position: "absolute",
            zIndex: 2,
          }}
          ref={(el) => (this.canvas = el)}
        ></canvas> */}
        {/* <Canvas/> */}
        <div className="footer__head-img">
          <div id="holder">
            <div className="dot1"></div>
            <div className="pulse1" onMouseOver={()=>setcountry('France')} onMouseLeave={()=>setcountry('')}>
              <p style={{display:country=="France"?"block":"none"}}><Fade bottom >France</Fade></p>
            </div>

            <div className="dot2"></div>
            <div className="pulse2" onMouseOver={()=>setcountry('Lebanon')} onMouseLeave={()=>setcountry('')}>
              <p style={{display:country=="Lebanon"?"block":"none"}}><Fade bottom >Lebanon</Fade></p>
              </div>

            <div className="dot3"></div>
            <div className="pulse3" onMouseOver={()=>setcountry('U.A.E.')} onMouseLeave={()=>setcountry('')}>
              <p style={{display:country=="U.A.E."?"block":"none"}}><Fade bottom >U.A.E.</Fade> </p>
              </div>

            {/* <div className="dot4"></div>
            <div className="pulse4" onMouseOver={()=>setcountry('Kenya')} onMouseLeave={()=>setcountry('')}>
              <p style={{display:country=="Kenya"?"block":"none"}}><Fade bottom >Kenya</Fade></p>
              </div> */}

            {/* <div className="dot5"></div>
            <div className="pulse5" onMouseOver={()=>setcountry('Ivory Coast')} onMouseLeave={()=>setcountry('')}>
              <p style={{display:country=="Ivory Coast"?"block":"none"}}><Fade bottom >Ivory Coast</Fade></p>
              </div> */}
          </div>
          <img src={map} />
        </div>

        <div className="footer__head-end">
          <Link to="/" smooth="true" className="logo animationFooter nav-link">
            <img style={{ height: "40px" }} src={logo} />
          </Link>
          <div className="footer__head-end-sections">
            <div className="footer__head-end-sections-pages">
              {nav.map((section, i) => (
                <Link
                  key={i}
                  to={section.link}
                  smooth="true"
                  //   className={linkClassName}
                  //   onClick={onClick}
                >
                  {section.title}
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className="footer__head-contact">
          <div className="footer__head-contact-socialmedia">
            <a target="_blank" href="https://www.facebook.com/Moobitek">
              <img src={fbIcon} />
            </a>
            <a target="_blank" href="https://www.instagram.com/moobitek.leb/">
              <img src={instaIcon} />
            </a>
            <a target="_blank" href="https://www.linkedin.com/company/moobitek">
              <img src={linkedinIcon} />
            </a>
            <a target="_blank" href="https://twitter.com/moobitek">
              <img src={twitterIcon} />
            </a>
          </div>
          <h6>&#169; 2021 - Moobitek all rights reserved</h6>
        </div>
      </div>
    </div>
  );
};
