import React, { useState } from "react";
import { Link } from "react-router-dom";

export const DefaultBtn = ({ to, content, width }) => {
  const w = width ? width : "180";
  const Type = to ? Link : "div";

  const h = width ? 2 : 0;
  return (
    <Type
      className="default-btn"
      to={to}
      style={{
        paddingLeft: width ? "10px" : 0,
        paddingRight: width ? "10px" : 0,
       
      }}
    >
      <svg
        width={`${w}px`}
        height={`${44+h}px`}
        viewBox={`0 0 ${w} ${44+h}`}
        className="border"
        style={{ top: h==2?"-2px":"0"}}
      >
        <polyline
          points={`${w - 30},1 ${w - 20},${43+h} 1,${43+h} 1,1 ${w - 1},1`}
          className="bg-line"
        />
        <polyline
          points={`${w - 20},1 ${w - 10},${43+h} 1,${43+h} 1,1 ${w - 1},1`}
          className="hl-line"
        />
      </svg>
      {content}
    </Type>
  );
};
