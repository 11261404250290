import React, { useEffect, useState, useRef } from "react";

import {
  creativeIcon,
  endToEndIcon,
  fiftyIcon,
  techConsIcon,
  tenIcon,
  uxuiIcon,
  idea,
  uxDesignIcon,
  webDevIcon,
  mobileDevIcon,
  aiIcon,
  projMIcon,
  itConsIcon,
  nolight,
  yeslight,
} from "../../components/UI/Library";
import { DefaultBtn } from "../../components/UI/buttons/DefaultBtn";
import { HeaderTitle } from "../../components/UI/rows/HeaderTitle";
import { Popup } from "../../components/UI/Popup";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import Flash from "react-reveal/Flash";
import Tada from "react-reveal/Tada";

export const Solutons = () => {
  const [modal, setModal] = useState(false);
  const [light, setlight] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setlight(true);
    }, 1000);

    return () => clearTimeout(timer);
  });

  const exp = [
    { icon: tenIcon, title: "Years of experience" },
    { icon: techConsIcon, title: "Technical Consultancy" },
    { icon: uxuiIcon, title: "UI/UX Approach" },
    { icon: endToEndIcon, title: "End-to-end Software development" },
    { icon: fiftyIcon, title: "A players" },
    { icon: creativeIcon, title: "Creative engineering mindset" },
  ];

  const commitments = [
    {
      icon: uxDesignIcon,
      title: "UX/UI Design",
      body:
        "Combining streamlined user experiences with outstanding visuals, our team of UI & UX experts will make sure that your product delivers the best journey to its users.",
    },
    {
      icon: webDevIcon,
      title: "Web Development",
      body:
        "We gather our web development experience of over a decade, to develop and deliver web apps that solve real problems.",
    },
    {
      icon: mobileDevIcon,
      title: "Mobile Development",
      body:
        "With a team of skilful mobile developers, we build top-notch Android, iOS, and cross-platform apps, from concept to a fully functional digital product.",
    },
    {
      icon: aiIcon,
      title: "Machine Learning",
      body:
        "We help you turn data and technology into your most powerful asset. Through advanced algorithms, we help you automate processes and prioritize routine decision-making.",
    },
    {
      icon: projMIcon,
      title: "Project Management and QA",
      body:
        "Your project is in good hands, so you do not need to worry about the project management, as we have it covered.",
    },
    {
      icon: itConsIcon,
      title: "IT Consulting",
      body:
        "Many products rise, only a few thrive on the market. As a tech partner, we will lead the line for you, making sure that your product is a front-runner.",
    },
  ];

  const steps = [
    {
      counter: "1",
      title: "Start from scratch",
      body: "From idea to market ready, we've got you covered.",
    },
    {
      counter: "2",
      title: "Ready for the next step",
      body:
        "In search of a team to help you get your product to the next level? We are here for you.",
    },
    {
      counter: "3",
      title: "Subtle improvements",
      body:
        "Need to refine or add new features to your product? We can ease the process.",
    },
  ];

  return (
    <div className="solutions">
      <Popup display={modal} setModal={setModal} type={"Quotation"}/>
      <div className="solutions__intro">
        <div className="solutions__intro-row">
          <HeaderTitle
            preTitle="SOFTWARE DEVELOPMENT SERVICES"
            title="From idea to a market-ready product and everything in between."
          />
          <div className="solutions__intro-row-body">
            <Fade left>
              <div className="solutions__intro-row-body-description">
                <p className="paragraph">
                  We empower ideas through technology. It is what we do best,
                  and we are proud of it. We nurture and enrich every project we
                  work with by adding our passion, dedication, involvement, and
                  knowledge.
                </p>
                <DefaultBtn to="/our-process" content="OUR PROCESS" />
              </div>
            </Fade>

            <div className="solutions__intro-row-body-img">
              
              <Fade>
                <Tada>
                  <img src={light ? yeslight : nolight} />
                </Tada>
              </Fade>
            </div>
          </div>
        </div>
      </div>
      <div className="solutions__ourExperience">
        <div className="solutions__ourExperience-row">
          {exp.map((e, i) => (
            <Fade bottom delay={i * 200} big>
              <div key={i} className="solutions__ourExperience-row-gridcard">
                <img src={e.icon} />
                <h3>{e.title}</h3>
              </div>
            </Fade>
          ))}
        </div>
      </div>
      <div className="solutions__rowTitle">
        <div className="solutions__rowTitle-row">
          <h2>“We are committed to offer you cutting-edge solutions.”</h2>
        </div>
      </div>
      <div className="solutions__whatCommitments">
        <div className="solutions__whatCommitments-row">
          {commitments.map((e, i) => (
            <Fade bottom delay={i * 100} big>
              <div key={i} className="solutions__whatCommitments-row-gridcard">
                <img src={e.icon} />
                <h2>{e.title}</h2>
                <p className="paragraph">{e.body}</p>
              </div>
            </Fade>
          ))}
        </div>
      </div>
      <div className="solutions__currentPhase">
        <div className="solutions__currentPhase-row">
          <h2>Where are you right now?</h2>

          <div className="solutions__currentPhase-row-transparentCard">
            <Fade bottom>
              {steps.map((e, i) => (
                <div
                  key={i}
                  className="solutions__currentPhase-row-transparentCard-card "
                >
                  {/* border-animation  */}
                  {/* <span className="border-animation__inner"> */}
                  <h1>{e.counter}</h1>
                  <h2>{e.title}</h2>
                  <p className="paragraph">{e.body}</p>
                  {/* </span> */}
                </div>
              ))}
            </Fade>
          </div>
          <span onClick={() => setModal(true)}>
            <DefaultBtn content="TELL US ABOUT YOUR PROJECT" width={260} />
          </span>
        </div>
      </div>

      <div className="solutions__howWeWork">
        <div className="solutions__howWeWork-row">
          <Fade left>
            <div className="solutions__howWeWork-row-flexcard">
              <Link
                className="solutions__howWeWork-row-flexcard-links"
                to="/our-process"
              >
                OUR PROCESS
              </Link>
            </div>
          </Fade>
          <Fade right>
            <div className="solutions__howWeWork-row-flexcard">
              <Link
                className="solutions__howWeWork-row-flexcard-links"
                to="/our-work"
              >
                OUR WORK
              </Link>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
};
