import logo from "./logo.svg";
import "./App.scss";
import Header from "./components/header/Header";
import { Router, Switch, Route } from "react-router-dom";
import { Homepage } from "./containers/Homepage/Homepage";
import { OurWork } from "./containers/OurWork/OurWork";
import { Parallax, Background } from "react-parallax";
import pic from "./assets/pic6.jpg";
import { Solutons } from "./containers/Solutions/Solutons";
import { About } from "./containers/About/About";
import { ContactUs } from "./containers/Contact/ContactUs";
import { OurProcess } from "./containers/OurProcess/OurProcess";
import { Footer } from "./components/footer/Footer.js";
import ScrollToTop from "./components/UI/ScrollToTop";

function App() {
  return (
    <div className="mainContainer">
      <ScrollToTop>
        <Header />

        <div className="container">
          <Switch>
            <Route exact path="/" component={Homepage} />
            <Route exact path="/our-work" component={OurWork} />
            <Route exact path="/solutions" component={Solutons} />
            <Route exact path="/about" component={About} />
            <Route exact path="/contact" component={ContactUs} />
            <Route exact path="/our-process" component={OurProcess} />
          </Switch>
        </div>

        <Footer />
      </ScrollToTop>
    </div>
  );
}

export default App;
