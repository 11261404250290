//Navbar.js
import React from "react";
import { Link } from "react-router-dom";



const Navbar = ({ navClass, linkClassName,onClick,nav,selected }) => (
  <NavComponent navClass={navClass} linkClassName={linkClassName} selected={selected}   nav={nav}  onClick={onClick} />
);

export const NavComponent = ({ onClick, navClass, linkClassName,nav,selected }) => (
  <nav className={navClass}>
    {nav.map((section, i) => (
      <Link
        key={i}
        to={section.link}
        smooth="true"
        className={linkClassName + (section.title==selected?" active":"")}
        onClick={()=>onClick(section.title)}
      >
        {section.title}
      </Link>
    ))}
  </nav>
);
export default Navbar;
