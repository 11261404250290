import React, { useState } from "react";
import { HeaderTitle } from "../../components/UI/rows/HeaderTitle";
import { contactus } from "../../components/UI/Library";
import { DefaultBtn } from "../../components/UI/buttons/DefaultBtn";
import { Popup } from "../../components/UI/Popup";

export const ContactUs = () => {
  const [modal, setModal] = useState(false);
  return (
    <div className="contact">
      <Popup display={modal} setModal={setModal}  type={"Quotation"}/>
      <div className="contact__head">
        <div className="contact__head-row">
          <HeaderTitle
            preTitle="CONTACT"
            title="We can turn your idea into reality, take over your existing project or upgrade your product."
          />
        </div>
      </div>
      <div className="contact__details">
        <div className="contact__details-row">
          <div className="contact__details-row-general fadeInLeft">
            <h2>General</h2>
            <a className="links" href="tel:+9611985666">
              HR: +961 1 985 666 
            </a>
            <br/>
            <a className="links" href="mailto:info@moobitek.com">
              info@moobitek.com
            </a>
            <span className="button" onClick={() => setModal(true)}>
              <DefaultBtn content={"GET A QUOTE"} />
            </span>
            <div className="contact__details-row-general-socialMedia ">
              <a
                target="_blank"
                className="links"
                href="https://www.facebook.com/Moobitek"
              >
                Facebook
              </a>
              <a
                target="_blank"
                className="links"
                href="https://www.instagram.com/moobitek.leb/"
              >
                Instagram
              </a>
              <a
                target="_blank"
                className="links"
                href="https://www.linkedin.com/company/moobitek"
              >
                Linkedin
              </a>
              <a
                target="_blank"
                className="links"
                href="https://twitter.com/moobitek"
              >
                twitter
              </a>
            </div>
          </div>
          <div className="contact__details-row-office fadeInLeft1">
            <h2>Offices</h2>
            <p>
              <b>U.A.E - Sharjah Media City (Shams), Al Messaned, Al Bataeh, Sharjah, United Arab Emirates</b>
            </p>
          </div>
          <div className="contact__details-row-img slideleft" >
            <img src={contactus} />
          </div>
        </div>
      </div>
    </div>
  );
};
