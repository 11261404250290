import React, { useState } from "react";
import { Parallax } from "react-parallax";
import { Cards } from "../../components/UI/card/Cards";

import {
  airtelTigoLogo,
  background,
  frame1,
  frame,
  frame2,
  web1,
  mobile1,
  cms,
  cmsMobile,
  platforms,
  platformsMobile,
  maleSpeakerBlurred,
  meetingBlurred,
  ufaLogo,
  inimoneyLogo,
  cameleonLogo,
  riskLogo,
  barentsLogo,
  aqeedLogo,
  vodacomLogo,
  freeLogo,
  safaricomLogo,
  moovLogo,
  orangeLogo,
  airtelLogo,
  mtnLogo,
  vodafoneLogo,
  workEnvironment,
  homeValue1,
  homeValue2,
  homeValue3,
  homeValue4,
  homeValue5,
  uxUiIcon,
  webDevIcon,
  mobileDevIcon,
  aiIcon,
  equalIcon,
  moneyIcon,
  uxDesignIcon,
  web2,
} from "../../components/UI/Library";
import { CustomBtn } from "../../components/UI/buttons/CustomBtn";
import { RowContainer } from "../../components/UI/rows/RowContainer";
import { TextBtn } from "../../components/UI/rows/TextBtn";
import { WidgetContainer } from "../../components/UI/rows/WidgetContainer";
import { Carousel } from "../../components/UI/rows/Carousel";
import { DefaultBtn } from "../../components/UI/buttons/DefaultBtn";
import { Popup } from "../../components/UI/Popup";
import { useWindowWidthAndHeight } from "../../components/hooks/CustomHooks";
import { OnScrollY } from "../../components/hooks/OnScrollY";
import Fade from "react-reveal/Fade";
import Slide from 'react-reveal/Slide';

export const Homepage = () => {
  const [modal, setModal] = useState(false);
  // console.log(OnScrollY());
  const partners = [
    { logo: ufaLogo, width: 150 },
    { logo: inimoneyLogo, width: 140 },
    { logo: cameleonLogo, width: 135 },
    { logo: riskLogo, width: 170 },
    // { logo: barentsLogo, width: 155 },
    // { logo: aqeedLogo, width: 120 },
    { logo: equalIcon, width: 85 },
    { logo: moneyIcon, width: 100 },
    { logo: vodacomLogo, width: 125 },
    { logo: freeLogo, width: 85 },
    { logo: safaricomLogo, width: 130 },
    { logo: moovLogo, width: 95 },
    { logo: orangeLogo, width: 87 },
    { logo: airtelLogo, width: 91 },
    { logo: mtnLogo, width: 105 },
    { logo: vodafoneLogo, width: 125 },
    { logo: airtelTigoLogo, width: 125 },
  ];

  const us = [
    {
      img: homeValue1,
      class: "fit homeValue1",
      title: "Togetherness",
      text: "Togetherness - We enjoy achieving our goals together",
    },
    {
      img: homeValue2,
      class: "fit homeValue2",
      title: "Care",
      text: "We take care of and accept each other",
    },
    {
      img: homeValue3,
      class: "fit homeValue3",
      title: "Growth",
      text: "We want to grow and strive for constant learning",
    },
    {
      img: homeValue4,
      class: "fit homeValue4",
      title: "Fulfillment",
      text: "We identify heavily with our tasks and clients",
    },
    {
      img: homeValue5,
      class: "fit homeValue5",
      title: "Fun",
      text: "We believe that work should also be fun",
    },
  ];

  const expertise = [
    { icon: uxDesignIcon, title: "UI/UX Design" },
    { icon: webDevIcon, title: "Web App Development" },
    { icon: mobileDevIcon, title: "Mobile App Development" },
    { icon: aiIcon, title: "Machine Learning" },
  ];

  return (
    <div className="Homepage">
      <Popup display={modal} setModal={setModal}   type={"Quotation"}/>
      <div className="Homepage__headSvg">
      <Slide left>
        <div className="Homepage__headSvg-row">
          <div className="Homepage__headSvg-row-back" />
          <div className="Homepage__headSvg-row-title">
          
             <div className="Homepage__headSvg-row-title-back">
              <div className="Homepage__headSvg-row-title-back-title1">
                <h2>We believe that software development ... </h2>
              </div>
            </div>
        
           
            <div className="Homepage__headSvg-row-title-title2">
              <h2>is more than coding.</h2>
            </div>
          </div>
        </div>
        </Slide>
      </div>
      <div className="Homepage__presentation"></div>
      {/* <Parallax
        blur={{ min: -5, max: 5 }}
        bgImage={background}
        bgImageAlt="coding"
        strength={500}
        className="Homepage__presentation"
      >
    
      </Parallax> */}

      <div className="Homepage__offer">
        <div className="Homepage__offer-row">
        <Fade left delay="200">
          <div className="Homepage__offer-row-one">
            <h2>That’s why we are committed to offering</h2>
          </div>
          </Fade>
          <span className="Homepage__offer-row-lonelyArrow arrow"></span>
          <div className="column Homepage__offer-row-arrows">
            <span className=" Homepage__offer-row-arrows-1 arrow"></span>
            <span className="Homepage__offer-row-arrows-2 arrow"></span>
            <span className="Homepage__offer-row-arrows-3 arrow"></span>
          </div>
          <div className="column Homepage__offer-row-offers">
            <Fade right delay="100">
              <div className="details">
                <img src={frame1} /> <p>Cutting Edge Solutions.</p>
              </div>
            </Fade>

            <Fade right delay="200">
              <div className="details">
                <img src={frame2} /> <p>A Client-First Approach.</p>
              </div>
            </Fade>

            <Fade right delay="300">
              <div className="details">
                <img src={frame} /> <p>Technical Consultancy.</p>
              </div>
            </Fade>
          </div>
        </div>
      </div>

      <WidgetContainer
        title="Our way of doing things?"
        spacedBody={
          <>
            <p className="paragraph">
              Over the past decade, we have perfected our end-to-end product
              development process to cover the entire life cycle of a product,
              customizing the development process to fit each product we work
              on. In the long run, we are more than your software development
              company –- we are your tech partner.
            </p>
            <DefaultBtn content="LEARN MORE" to="/our-process" />
          </>
        }
        img1={workEnvironment}
      />

      <div className="Homepage__ourExpertise">
        <div className="Homepage__ourExpertise-row">
          <div className="Homepage__ourExpertise-row-text">
            <h2>We master technology and we bring value when it comes to:</h2>
            <CustomBtn to="/solutions" title="VIEW FULL SOLUTIONS" />
          </div>

          <div className="Homepage__ourExpertise-row-cards">
            {expertise.map((e, i) => (
              <Fade right delay={i * 200}>
                <Cards key={i} item={e} />
              </Fade>
            ))}
          </div>
        </div>
      </div>

      <TextBtn
        text="Your idea matters, and our work is meant to make the difference."
        btn="LET’S DISCUSS"
        setModal={setModal}
      />

      <RowContainer
        title="Mobile apps- websites"
        body="Moobitek is a mobile app and website development company that can turn all of your ideas into reality. Our team consists of highly trained mobile app and website developers capable of delivering projects at the highest professional standards and in time."
        img={meetingBlurred}
        parentClass="Homepage__ourWayRow"
        subImg={web2}
        extraImg={mobile1}
      />

      <RowContainer
        title="Digital Platforms "
        body="Moobitek has high expertise in combining the building and integration of digital platforms into your internal processes. We combine your product vision with our technical expertise to create integrated solutions for your business, enabling you to unleash the full potential of your business."
        img={maleSpeakerBlurred}
        parentClass="Homepage__switch-ourWayRow"
        subImg={platforms}
        extraImg={platformsMobile}
        turn={true}
      />

      <RowContainer
        title="Content Managements System"
        body="Content creation and management have become one of the keystones of a successful online presence for any kind of business. Moobitek can offer custom CMS development services to facilitate your content management for you to be able to manage your platform without any external effort at any time."
        parentClass="Homepage__ourWayRow"
        subImg={platforms}
        extraImg={cmsMobile}
      />
      <div className="Homepage__partners">
        <div className="Homepage__partners-row">
          <h2>Clients & Partners</h2>
          <div className="Homepage__partners-row-slider">
            <Carousel imgs={partners} />
            {partners.map((e, i) => (
              <div key={i} className="Homepage__partners-row-slider-slide">
                <img src={e.logo} style={{ width: e.width }} />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className=" Homepage__us">
        <div className="Homepage__us-row">
          <div className="Homepage__us-row-head">
            <h2>Values</h2>
            <div className="Homepage__us-row-head-text">
              <h4>That bring us together</h4>
              <CustomBtn to="/about" title="DISCOVER MORE" />
            </div>
          </div>

          <div className="Homepage__us-row-grid">
            {us.map((e, i) => (
              <Fade
                top={i < 2}
                bottom={i > 1}
                right={((i + 1) % 2 == 0 && i < 3) || i == 4}
                left={(i + 1) % 2 != 0 && i != 4}
              >
                <div key={i} className={"flip " + e.class}>
                  <div className="flip-inner">
                    <div className="flip-inner-front">
                      <img src={e.img} />
                    </div>
                    <div
                      className="flip-inner-back "
                      style={{ backgroundImage: `url(${e.img})` }}
                    >
                      <div className="flip-inner-back-shadow ">
                        <h2>{e.title}</h2>
                        <p className="paragraph">{e.text}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Fade>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
