import React from "react";
import {
  aboutus1,
  togethernessIcon,
  careIcon,
  fullfilmentIcon,
  growthIcon,
  funIcon,
  it,
} from "../../components/UI/Library";
export const WindowValues = ({ page }) => {
  return (
    <>
      <div className="about__values-row">
        <div className={"about__values-row-square " + (page ? "bounce" : "")}>
          <div className="about__values-row-square-content ">
            <img src={careIcon} />
            <h4>Care</h4>
          </div>
        </div>
        <div className={"about__values-row-square " + (page ? "bounce" : "")}>
          <div className="about__values-row-square-content ">
            <img src={fullfilmentIcon} />
            <h4>Fulfillment</h4>
          </div>
        </div>
      </div>
      <div className="about__values-row">
        <div className={"about__values-row-square " + (page ? "bounce" : "")}>
          <div className="about__values-row-square-content">
            <img src={togethernessIcon} />
            <h4>Togetherness</h4>
          </div>
        </div>
        <div className={"about__values-row-square " + (page ? "bounce" : "")}>
          <div className="about__values-row-square-content">
            <img src={growthIcon} />
            <h4>Growth</h4>
          </div>
        </div>
        <div className={"about__values-row-square " + (page ? "bounce" : "")}>
          <div className="about__values-row-square-content">
            <img src={funIcon} />
            <h4>Fun</h4>
          </div>
        </div>
      </div>
    </>
  );
};
