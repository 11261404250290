import React from "react";

export const FilledBlock = ({ top, number, title, body }) => {
  return (
    <div className="filled">
      <h1>
        <b>{number}</b>
      </h1>
      <h2 >{title}</h2>
      <h4>{body}</h4>
      <div className="arrow-parent" style={{top:0,position:top?"absolute":""}}>
        <div className="arrow" />
      </div>
    </div>
  );
};
