import React, { useState } from "react";
import { logo } from "./Library";
import { DefaultBtn } from "./buttons/DefaultBtn";
import axios from "axios";
const { REACT_APP_API_ENDPOINT } = process.env;

export const Popup = ({ display, setModal, type }) => {
  //   return <div className="fill-window" style={{display:display}}> Content </div>;
  const [name, setname] = useState(null);
  const [email, setemail] = useState(null);
  const [message, setmessage] = useState(null);
  const [error, seterror] = useState("");

  const submit = () => {
    if (
      name == null ||
      name.length == 0 ||
      email == null ||
      email.length == 0 ||
      message == null ||
      message.length == 0
    ) {
      seterror("All fields must be filled and valid");
    } else {
      axios({
        method: "POST",
        url: REACT_APP_API_ENDPOINT,
        data: {
          messageHtml: `<div> <p>Name: ${name} </p><p>Email: ${email} </p><p>Message: ${message}</p></div>`,
          type
        },
      }).then((response) => {
        if (response.data.msg === "success") {
          seterror("Email sent!");
          resetForm();
        } else if (response.data.msg === "fail") {
          seterror("Oops, something went wrong. Try again");
        }
      });
    }
  };

  const resetForm = () => {
    setname("");
    setemail("");
    setmessage("");
    seterror("");
    setModal(false);
  };
  return (
    <div
      id="myModal"
      className={"modal " + (display ? "open" : "")}
      style={{ display: display ? "block" : "none" }}
    >
      <div className="modal-content">
        <div className="modal-content-head">
          <img src={logo} />
          <span
            className="modal-content-head-close"
            onClick={() => setModal(false)}
          >
            &times;
          </span>
        </div>
        <div className="modal-content-form">
          <h2 className="modal-content-form-title">
            Leave your contact info — hear from us the same day!
          </h2>
          <label for="form-field-name " className="modal-content-form-label">
            <span
              style={{
                color: name != null && name.length == 0 ? "red" : "black",
              }}
            >
              Your full name*
            </span>
          </label>
          <input
            size="1"
            type="text"
            name="form_fields[name]"
            id="form-field-name"
            className="elementor-field   modal-content-form-input"
            required="required"
            aria-required="true"
            value={name}
            defaultValue={name}
            onChange={(e) => setname(e.target.value)}
          />
          <label for="form-field-email" className="modal-content-form-label">
            <span
              style={{
                color: email != null && email.length == 0 ? "red" : "black",
              }}
            >
              Your email address*
            </span>
          </label>
          <input
            size="1"
            type="email"
            name="form_fields[email]"
            id="form-field-email"
            className="elementor-field  modal-content-form-input"
            required="required"
            aria-required="true"
            value={email}
            defaultValue={email}
            onChange={(e) => setemail(e.target.value)}
          />
          <label
            for="form-field-plannermessage"
            className="modal-content-form-label"
          >
            <span
              style={{
                color: message != null && message.length == 0 ? "red" : "black",
              }}
            >
              Your message*
            </span>
          </label>
          <textarea
            className=" elementor-field  modal-content-form-input"
            name="form_fields[plannermessage]"
            id="form-field-plannermessage"
            rows="1"
            required="required"
            aria-required="true"
            value={message}
            defaultValue={message}
            onChange={(e) => setmessage(e.target.value)}
          ></textarea>
          <p style={{ mareginBottom: "30", color: "red" }}> {error}</p>
          <span onClick={() => submit()}>
            <DefaultBtn content="SEND" />
          </span>
        </div>
      </div>
    </div>
  );
};
