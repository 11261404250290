import React from "react";
import { Link } from "react-router-dom";
import { NavComponent } from "./Navbar";

export const SmallScreensNavbar = ({ checkbox, setChecbox,nav }) => {

  return (
    <nav>
      <input
        type="checkbox"
        href="#"
        className="menu-open"
        name="menu-open"
        id="menu-open"
        checked={checkbox}
      />

      <label
        className="menu-open-button"
        for="menu-open"
        onClick={() => setChecbox(!checkbox)}
      >
        <span className="hamburger hamburger-1"></span>
        <span className="hamburger hamburger-2"></span>
        <span className="hamburger hamburger-3"></span>
      </label>
      <div className="sections">
        {nav.map((e, i) => (
          <Link
            key={i}
            to={e.link}
            className={"menu-item sections-" + (checkbox ? "open" : "none")+(i==(nav.length-1)?" lastRow":"")}
            onClick={() => setChecbox(!checkbox)}
          >
            {e.title}
          </Link>
        ))}
      </div>
    </nav>
  );
};
