import React from "react";
import "./Card.scss";

export const Cards = ({ item }) => {
  return (
    <div className="card">
      <div>
        <img src={item.icon} />
        <p>{item.title}</p>
      </div>
    </div>
  );
};
