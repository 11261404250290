import React, { useState, useEffect } from "react";
import Typist from "react-typist";

export const HeaderTitle = ({ preTitle, title, postTtile }) => {
  return (
    <div className="general">
      <div className="title">
        <p className="title-t1">{preTitle}</p>
        <p className="title-t2">
          <Typist avgTypingDelay={50} cursor={{ hideWhenDone: true }}>
            <span> {title}</span>
          </Typist>
        </p>
        <p className="title-t3">{postTtile}</p>
      </div>
    </div>
  );
};
