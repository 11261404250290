// Header.js
import React, { useState } from "react";
import Navbar from "./Navbar";
import { useWindowWidthAndHeight } from "../hooks/CustomHooks";

import { Link } from "react-router-dom";
import { logo } from "../UI/Library";
import { SmallScreensNavbar } from "./SmallScreensNavbar";

const Header = () => {
  // use our custom hook to get the the window size
  const [width, height] = useWindowWidthAndHeight();
  const [checkbox, setChecbox] = useState(false);
  const [selected, setSelected] = useState("home")

  const nav = [
    { title: "OUR WORK", link: "our-work" },
    { title: "SOLUTIONS", link: "solutions" },
    { title: "ABOUT", link: "about" },
    { title: "CONTACT", link: "contact" },
  ];

  return (
    <header className={(checkbox && width < 1000) ? "header " : ""}>
      <div className="header-inner">
        <Link
          to="/"
          smooth="true"
          className="logo animationHeader nav-link"
          onClick={() => {setChecbox(false); setSelected("home")}}
        >
          <img style={{height:"40px"}} src={logo} />
        </Link>
        {/*if the width of the window is bigger than 1000px use <Navebar/>,
                   else user <SmallScreensNavbar/>*/}
        {width > 1000 ? (
          <Navbar navClass="nav-big" selected={selected} linkClassName="nav-big-link"  nav={nav} onClick={(e)=>setSelected(e)} />
        ) : (
          <SmallScreensNavbar checkbox={checkbox} setChecbox={setChecbox} nav={nav} />
        )}
      </div>
    </header>
  );
};

export default Header;
