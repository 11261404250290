import React from "react";
import { FilledBlock } from "./FilledBlock";
import {
  UIUXProcessIcon,
  marketStudyIcon,
  devBlueIcon,
  planningBlueIcon,
  qaBlueIcon,
  deploymentBlueIcon,
} from "../../components/UI/Library";
import Fade from 'react-reveal/Fade';

export const MobileProcess = () => {
  const data = [
    {
      number: "01",
      title: "Market Study",
      body:
        "After the client's request, it is a must to conduct extensive research with an in-depth market study.",
      img: marketStudyIcon,
    },
    {
      number: "02",
      title: "Planning",
      body:
        "We set our precise work process and how it will be executed. All steps needed will be outlined as part of a full project management plan.",
      img: planningBlueIcon,
    },
    {
      number: "03",
      title: "UX / UI",
      body:
        "Our designers wireframe and build test spaces as first mock-ups, which then triggers the UX / UI design development.",
      img: UIUXProcessIcon,
    },
    {
      number: "04",
      title: "Development",
      body:
        "Our team start development with a test area for all parties to have full transparency at all times. The development consists of both the front and back end",
      img: devBlueIcon,
    },
    {
      number: "05",
      title: "QA / Testing",
      body:
        "The final part before the project goes live, where our Q&A team will be testing the whole environment for any bugs and issues they may encounter. This leads to a 100% refined and clean new web or app.",
      img: qaBlueIcon,
    },
    {
      number: "06",
      title: "Deployment",
      body: "The project is switched online and live",
      img: deploymentBlueIcon,
    },
  ];
  return (
    <>
      <div className="ourProcess__process-mobile">
        {data.map((e, i) => (
          <Fade right={(i + 1) % 2 == 0 } left={!((i + 1) % 2 == 0 )}>
            <div
              className={
                "ourProcess__process-mobile-row " +
                ((i + 1) % 2 == 0 ? "right" : "left")
              }
            >
              <img src={e.img} />
              <span className="ourProcess__process-mobile-row-arrow">
                <span />
              </span>
              <FilledBlock
                top={i == 0 ? true : false}
                number={e.number}
                title={e.title}
                body={e.body}
              />
              <div className="space" />
            </div>
          </Fade>
        ))}
      </div>
    </>
  );
};
