import React from "react";
import {
  aboutus1,
  togethernessIcon,
  careIcon,
  fullfilmentIcon,
  growthIcon,
  funIcon,
  it,
} from "../../components/UI/Library";
import Fade from "react-reveal/Fade";

export const MobileValues = () => {
  const values = [
    { title: "Care", icon: careIcon },
    { title: "Fulfillment", icon: fullfilmentIcon },
    { title: "Togetherness", icon: togethernessIcon },
    { title: "Growth", icon: growthIcon },
    { title: "Fun", icon: funIcon },
  ];
  return (
    <>
      <div className="about__values-row-mobile">
        {values.map((e, i) => {
          const card = (
            <Fade left={i % 2 == 0} right={!(i % 2 == 0)}>
              <div className="about__values-row-mobile-line">
                <img src={e.icon} />
                <h4>{e.title}</h4>
              </div>
            </Fade>
          );
          let resp =
            i % 2 == 0 ? (
              <>
                {card}
                {i == values.length - 1 ? (
                  <></>
                ) : (
                  <Fade delay={300}>
                    <div className="about__values-row-mobile-link">
                      <div className="about__values-row-mobile-link-borderRight" />
                    </div>
                  </Fade>
                )}
              </>
            ) : (
              <>
                {i == values.length - 1 ? (
                  <></>
                ) : (
                  <Fade delay={300}>
                    <div className="about__values-row-mobile-link">
                      <div className="about__values-row-mobile-link-borderLeft" />
                    </div>
                  </Fade>
                )}

                {card}
              </>
            );
          return resp;
        })}
      </div>
    </>
  );
};
