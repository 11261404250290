import React, { useState, useRef, useEffect } from "react";
import {
  aboutus1,
  togethernessIcon,
  careIcon,
  fullfilmentIcon,
  growthIcon,
  funIcon,
  us1,
  us2,
  us3,
  us4,
  us6,
  it,
  homeValue2,
  homeValue1,
} from "../../components/UI/Library";
import Reveal from "react-reveal/Reveal";
import { HeaderTitle } from "../../components/UI/rows/HeaderTitle";
import { OppWidgetContainer } from "../../components/UI/rows/OppWidgetContainer";
import { TextBtn } from "../../components/UI/rows/TextBtn";
import { MobileValues } from "./MobileValues";
import { useWindowWidthAndHeight } from "../../components/hooks/CustomHooks";
import { WindowValues } from "./WindowValues";
import { Popup } from "../../components/UI/Popup";
import { Carousel } from "../../components/UI/rows/Carousel";
import { OnScrollY } from "../../components/hooks/OnScrollY";
import Slide from "react-reveal/Slide";
import Fade from 'react-reveal/Fade';

export const About = () => {
  const stepsRef = useRef();
  const [stepsDom, setStepsDom] = useState(false);

  const teamRef = useRef();
  const [teamDom, setTeamDom] = useState(false);

  // const scrollHandler = (_) => {
  //   setStepsDom(stepsRef.current.getBoundingClientRect());
  //   setTeamDom(teamRef.current.getBoundingClientRect());

  //   // console.log(teamRef.current.getBoundingClientRect());
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", scrollHandler, true);
  //   return () => {
  //     window.removeEventListener("scroll", scrollHandler, true);
  //   };
  // }, []);

  const [modal, setModal] = useState(false);
  const [width, height] = useWindowWidthAndHeight();
  const { currentScrollY, page } = OnScrollY();

  const steps = [
    { class: "right none", title: null, body: null },
    {
      vectorLeft: "vectorLeft first",
      class: "padding",
      title: "Our Mission ",
      body: "We create value through technology within honest partnerships.",
      left: true,
    },
    {
      vector: "vector",
      class: "right",
      title: "Our Vision",
      body: "A thriving society built on fair relations.",
      left: false,
    },
    { class: "left none", title: null, body: null },
    { class: "right none", title: null, body: null },
    {
      extra: "extra",
      vectorLeft: "vectorLeft",
      class: "padding",
      title: "Our Promise",
      body:
        "To lead the line and to deliver the best experience for our clients.",
      left: true,
    },
    {
      vector: "vector",
      class: "right",
      title: "Our Essence",
      body:
        "We are that perfect-imperfect mix of knowledge, passion and commitment.",
      left: false,
    },
    { class: "left none", title: null, body: null },
    { class: "right none", title: null, body: null },
    {
      extra: "extra",
      vectorLeft: "vectorLeft",
      class: "padding",
      title: "Our Vibe",
      body:
        "When you meet one of ours you instantly feel in the  place. Cool people, flexible environment and solution-oriented mindset.",
      left: true,
    },
    {
      vector: "vector",
      class: "right",
      title: "Our Work",
      body:
        "As software development, we often propose innovative solutions –- then lead the way. By staying true to our creativity, we stay relevant for clients in more than 15 industries worldwide.",
      left: false,
    },
    { class: "left none", title: null, body: null },
  ];

  const team = [
    { logo: us6 },

    { logo: us1 },
    { logo: us2 },
    // { logo: us3 },
    { logo: it },
  ];

  const componentShown = (dom, i) => {
    let height = dom.height;
    let top = dom.top;

    let avgPerComponent = height / i;
    let showingE = (height - top) / avgPerComponent;
    // console.log(showingE, avgPerComponent, i);
    return showingE;
  };
  return (
    <div className="about">
      <Popup display={modal} setModal={setModal}  type={"Quotation"}/>
      <div className="about__head">
        <div className="about__head-row">
          <HeaderTitle
            preTitle="ABOUT US"
            title="The best software engineering team to develop your digital product."
          />
        </div>
      </div>

      <div className="about__quote">
        <div className="about__quote-row">
          <OppWidgetContainer
            imgClass="representation"
            img1={aboutus1}
            // title="Small quote from the team, that goes here..."
            body="The best software engineering team to develop your digital product. We treat our clients as our partners. Having a vast amount of experience in software development, we identify businesses' needs and goals and offer an original mix of services to support our client’s goals and ambitions."
          />
        </div>
      </div>
      <div className="about__values">
        <div className="about__values-title">
          <h2>Our Values</h2>
        </div>
        {width < 1000 ? <MobileValues /> : <WindowValues page={page > 0.5} />}
      </div>
      <TextBtn
        text="Your idea matters, and our work is meant to make the difference."
        btn="LET’S DISCUSS"
        setModal={setModal}
      />

      <div className="about__steps" ref={stepsRef}>
        <div className="about__steps-row">
          {steps.map((e, i) => (
            <div key={i} className={"about__steps-row-block " + e.extra}>
              <div className={e.vectorLeft} />
              <div
                className={
                  "about__steps-row-block-content " + e.class
                  // (e.left == true &&
                  // componentShown(stepsDom, steps.length / 2) - 0.8 > i / 2
                  //   ? " slideleft"
                  //   : e.left == false &&
                  //     componentShown(stepsDom, steps.length / 2) - 0.8 > i / 2
                  //   ? width < 768
                  //     ? " slideleft"
                  //     : " slideright"
                  //   : "")
                  // (e.left == true && page > 1.2 + i / 10
                  //   ? " slideleft"
                  //   : e.left == false && page > 1.2 + i / 10
                  //   ? " slideright"
                  //   : "")
                }
              >
                <Slide
                  left={!e.left && width > 768}
                  right={e.left || (!e.left && width < 768)}
                >
                  <h2>{e.title}</h2>
                  <p className="paragraph">{e.body}</p>
                </Slide>
              </div>
              <div className={e.vector} />
            </div>
          ))}
          <div className="about__steps-row-bottom">
            <div className="about__steps-row-bottom-end" />
          </div>
        </div>
      </div>
      <Fade bottom big>
      <div
        ref={teamRef}
        className={
          "about__team " 
        }
      >
        <div className="about__team-row">
          <div className="about__team-row-text">
            <h2>Our Team</h2>
            <p className="paragraph">
              We are passionate technophiles, active lifestyle advocates, and
              care about what we do. We are introverts and extroverts,
              courageous and fearless, men and women, cool dads and cool moms.
            </p>
          </div>
        </div>
      </div>
      <div
        className={
          "about__team " 
        }
      >
        <div className="about__team-row ">
          <Carousel imgs={team} team={true} mobile={width < 1000} />
          {/* <img src={it} /> */}
        </div>
      </div>
      </Fade>
    </div>
  );
};
