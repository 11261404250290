import React from "react";
import { DefaultBtn } from "../buttons/DefaultBtn";
import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";
export const TextBtn = ({ text, btn, setModal }) => {
  return (
    <div className="general">
      <div className=" textBtnRow">
        <div className="row">
          <Fade big>
            <h3>{text} </h3>
          </Fade>
          <Slide right>
            <span onClick={() => setModal(true)}>
              <DefaultBtn content={btn} />
            </span>
          </Slide>
        </div>
      </div>
    </div>
  );
};
