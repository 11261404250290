import React from "react";
import Fade from "react-reveal/Fade";

export const OppWidgetContainer = ({
  spacedBody,
  imgClass,
  img1,
  img2,
  title,
  body,
}) => {
  return (
    <div className="oppWidgetContainer">
      <div className="oppWidgetContainer-row">
        <div className={imgClass}>
          <Fade left>
            <img className="img1" src={img1} />
          </Fade>
          <Fade left big>
            <img className="img2" src={img2} />
          </Fade>
        </div>
        <div
          className="widgetContainer-row-info"
          className="oppWidgetContainer-row-info"
        >
     
          <Fade right>
            <h2>{title}</h2>
            {spacedBody ? spacedBody : <p className="paragraph">{body}</p>}
          </Fade>
        </div>
      </div>
    </div>
  );
};
