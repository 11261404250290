import React from "react";
import {
  cms,
  cmsMobile,
  mobile2,
  web2,
  machineLearning1,
  platforms,
  platformsMobile,
  USSD,
  USSD2,
  mobile1,
} from "../../components/UI/Library";
import { HeaderTitle } from "../../components/UI/rows/HeaderTitle";
import { OppWidgetContainer } from "../../components/UI/rows/OppWidgetContainer";
import { WidgetContainer } from "../../components/UI/rows/WidgetContainer";

export const OurWork = () => {
  return (
    <div className="ourWork">
      <div className="ourWork__intro">
        <div className="ourWork__intro-row">
          <HeaderTitle
            preTitle="WEBSITE BY MOOBITEK"
            title="Our clients’ experience with us leads to digital products, perfectly engineered to match business goals with user needs."
            postTtile="  Only when our clients declare success, we celebrate our success."
          />
        </div>
      </div>
      <WidgetContainer
        title="Mobile apps- websites"
        body="Moobitek is a mobile app and website development company that can turn all of your ideas into reality. Our team consists of highly trained mobile app and website developers capable of delivering projects at the highest professional standards and in time."
        img1={web2}
        img2={mobile1}
      />
      <OppWidgetContainer
        imgClass="representation"
        img1={platforms}
        img2={platformsMobile}
        title="Digital Platforms"
        body="Moobitek has high expertise in combining the building and integration of digital platforms into your internal processes. We combine your product vision with our technical expertise to create integrated solutions for your business, enabling you to unleash the full potential of your business."
      />
      <WidgetContainer
        title="Content Managements System"
        body="Content creation and management have become one of the keystones of a successful online presence for any kind of business. Moobitek can offer custom CMS development services to facilitate your content management for you to be able to manage your platform without any external effort at any time."
        img1={cms}
        img2={cmsMobile}
      />
      <OppWidgetContainer
        imgClass="ussd"
        img1={USSD2}
        img2={USSD}
        title="USSD / SMS"
        body="Moobitek is a specialist in setting up Unstructured Supplementary Services Data (USSD) gateways, which provide custom-made user experiences to each mobile operators’ subscriber base. The solution can create new revenue streams for companies that want to reach a wide array of potential consumers directly via their handsets. Naturally, Moobitek also offers state-of-the-art SMS distribution services to promote your products and build new leads."
      />
      <WidgetContainer
        title="Machine Learning"
        body="Moobitek is covering a wide array of advanced problems in the field of data science and machine intelligence. We offer flexible, state-of-the-art, and scalable artificial intelligence services to a wide variety of industries. Our prime focus is to automate processes and gain data insights using deep learning techniques."
        img1={machineLearning1}
      />
    </div>
  );
};
