import React from "react";
import { Parallax } from "react-parallax";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";

export const RowContainer = ({
  title,
  body,
  parentClass,
  img,
  subImg,
  extraImg,
  turn,
}) => {
  return (
    <Parallax
      blur={{ min: -10, max: 10 }}
      bgImage={img}
      bgImageAlt="coding"
      // strength={300}
      className="cust-parallax"
    >
      <div className={parentClass}>
        <div className={parentClass + "-row"}>
          <div className={parentClass + "-row-sect1"}>
            <Fade left>
              <h2>{title}</h2>
              <p className="paragraph">{body}</p>
            </Fade>
          </div>
          <div className={parentClass + "-row-sect2"}>
            <Fade left={turn} right={!turn} big>
              <img className={parentClass + "-row-sect2-img1"} src={extraImg} />
            </Fade>
            <Flip left>
              <img className={parentClass + "-row-sect2-img2"} src={subImg} />
            </Flip>
          </div>
        </div>
      </div>
    </Parallax>
  );
};
