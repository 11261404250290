import React from "react";
import Fade from "react-reveal/Fade";

export const WidgetContainer = ({ title, body, img1, img2, spacedBody }) => {
  return (
    <div className="widgetContainer">
      <div className="widgetContainer-row">
        <div className="widgetContainer-row-info">
          <Fade left >
            <h2>{title}</h2>
            {spacedBody ? spacedBody : <p className="paragraph">{body}</p>}
          </Fade>
        </div>
        <div className="widgetContainer-row-representation">
          <Fade right>
            <img
              className="widgetContainer-row-representation-img1"
              src={img1}
            />
          </Fade>
          <Fade right big>
            <img
              className="widgetContainer-row-representation-img2"
              src={img2}
            />
          </Fade>
        </div>
      </div>
    </div>
  );
};
